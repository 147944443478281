import { combineReducers } from 'redux';

// Import your reducers here...
import AgentData from './agent';
import UserData from './user';
import Cities from './cities';
import Roadshow from './roadshow';
import Talkshow from './talkshow';
import Provinces from './provinces';

export default combineReducers({
    AgentData,
    UserData,
    Cities,
    Roadshow,
    Talkshow,
    Provinces
});