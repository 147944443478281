import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

// Import your pages component here...
const LandingPage = Loadable({
    loader: () => import('pages/landing'),
    loading: () => null
});
const MechanismPage = Loadable({
    loader: () => import('pages/mechanism'),
    loading: () => null
});
const RoadshowPage = Loadable({
    loader: () => import('pages/roadshow'),
    loading: () => null
});
const TalkshowhowPage = Loadable({
    loader: () => import('pages/talkshow'),
    loading: () => null
});

export default () => (
    <Router basename="/">
        <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/mekanisme" component={MechanismPage} />
            <Route path="/roadshow" component={RoadshowPage} />
            <Route path="/talkshow" component={TalkshowhowPage} />
            <Route path="/shortlist/confirm/:uid" component={LandingPage} />
            {/* <Route render={() => {
                const hash = window.location.hash;
                console.log(hash);

                return null;
            }} /> */}
        </Switch>
    </Router>
);